import React from 'react';
import UserProgress from './components/UserProgress'
const App = () => {
    return ( <
        div >
        <
        div className = "App" >
        <
        UserProgress userId = "67436427dda83c0c8c853ad5" / > { /* Replace with dynamic user ID */ } <
        /div> < /
        div >

    );
};

export default App;