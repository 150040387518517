import React, { useState, useEffect } from 'react';

const UserProgressGraph = ({ userId }) => {
    const [imageUrl, setImageUrl] = useState('');

    // Function to fetch the user progress image from the API
    const fetchUserProgress = () => {
        const url = `https://plankton-app-izckv.ondigitalocean.app/api/user-progress/${userId}/`;
        setImageUrl(url); // Update the image source with the URL
    };

    // Use useEffect to reload the image every 4 seconds
    useEffect(() => {
        fetchUserProgress(); // Initial fetch

        const interval = setInterval(() => {
            fetchUserProgress(); // Reload the image every 4 seconds
        }, 4000);

        // Clean up the interval on component unmount
        return () => clearInterval(interval);
    }, [userId]);

    return ( <
        div style = {
            { textAlign: 'center' } } >
        <
        h1 > User Progress Graph < /h1> <
        img id = "userProgressImage"
        src = { imageUrl }
        alt = "User Progress"
        style = {
            {
                maxWidth: '100%', // Makes the image responsive and fit the container
                height: 'auto', // Keeps the aspect ratio intact
                margin: '0 auto', // Centers the image
                display: 'block', // Ensures the image behaves as a block element
            }
        }
        /> <
        /div>
    );
};

export default UserProgressGraph;